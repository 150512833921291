import { FlexBox, TextField, theme, trackEvent } from '@rario/shared-components'
// import { CustomGutterSpaceRow } from 'components/Layout/Layout.styles'
import dynamic from 'next/dynamic'
import { FunctionComponent, useContext, useEffect } from 'react'
import { MobileHomePage } from 'interfaces/homePage'
import Header from 'components/Header'
import { useCustomUser } from 'contexts/CustomUserProvider'
import HomeLandingFooter from 'components/HomeLanding/HomeLandingFooter'
import FirstFoldCarousel from './FirstFoldCarousel'
// import HomePageWidgets from './HomePageWidgets'
// import LatestSalesListing from 'components/LatestSaleWidget/LatestSaleWidget'
import Link from 'next/link'
import ShutdownWidget from './ShutdownWidget'
// import ArcadeGameNudge from 'components/Arcade/ArcadeGameNudge'
import { InstantSearch, Configure, connectStats } from 'react-instantsearch-dom'
import { getSearchClient, isTypesenseEnabled } from 'utils/searchUtil'
import { BuyBackEstimateContext } from 'contexts/BuyBackEstimateContext'

const NavigationBar = dynamic(() => import('components/NavigationBar'))
// const StickyFloatingButton = dynamic(() => import('components/StickyFloatingButton'))
// const HowRarioWorksCard = dynamic(() => import('components/HowRarioWorksCard'))
// const LearnAboutD3 = dynamic(() => import('components/LearnAboutD3'))
// const PracticeMatchBanner = dynamic(() => import('components/WelcomeScreen/PracticeMatchBanner'))

const HomePage: FunctionComponent<{
  homePageData: MobileHomePage
  showAppDownloadStrip?: boolean
}> = ({ homePageData /* showAppDownloadStrip = false */ }) => {
  const { user: userData } = useCustomUser()
  // const { isRestricted, setShowRestrictedUserModal } = useContext(ModalContext)

  useEffect(() => {
    trackEvent('Section Viewed', 'track', {
      sub_category: 'homepage-special_app_hero_cards',
      type: 'carousel_cards',
      view_type: 'carousel_cards',
      category: 'homepage',
    })

    // if (isRestricted) {
    //   setShowRestrictedUserModal(true)
    // }
    // To trigger scroll handler on load
    document.documentElement.scrollTo({ top: 1, behavior: 'smooth' })
  }, [])

  const username = userData?.username || ''
  const searchConfig = {
    filters: `username:${username} ${isTypesenseEnabled() ? '&&' : 'AND'} is_opened:false`,
  }

  return (
    <InstantSearch
      searchClient={getSearchClient()}
      indexName={process.env.USER_VAULT_PACKS_INDEX_NAME || ''}
    >
      <Configure {...searchConfig} />
      <>
        {/* {showAppDownloadStrip && PLATFORM_ORIGIN === ThemeEnum.RARIO && (
        <FlexBox
          position={'relative'}
          cursor={'pointer'}
          height={'auto'}
          width={'100%'}
          maxWidth={theme.maxDesktopLayoutWidth}
          mx={'auto'}
          onClick={() => window && window.open(RARIO_WEBVIEW_APK_URL, '_parent')}
        >
          <Link href={'/app-download#installationSteps'} scroll={false}>
            <Image
              src={getCDNUrl('/App/images/rario-landing/homepage-download-strip.png', true)}
              height={'100%'}
              width={'100%'}
              name={'App Download Strip'}
            />
          </Link>
        </FlexBox>
      )} */}
        <FlexBox
          position={'absolute'}
          flexDirection="column"
          width="100%"
          maxWidth={['100%', theme.maxDesktopLayoutWidth]}
          top={'0px'}
        >
          <Header />
        </FlexBox>

        <FlexBox
          flexDirection="column"
          width="100%"
          mx="auto"
          minHeight="500px"
          overflow="hidden"
          margin="auto"
        >
          <FirstFoldCarousel data={homePageData} />

          {/* <StickyFloatingButton /> */}

          <NavigationBar />

          {/* <LatestSalesListing /> */}

          <FlexBox flexDirection="column" mx="16px" mt="34px">
            <ShutdownWidget variant="marketplace" />
            <FlexBox mt="18px" mb="6px">
              <ShutdownWidget variant="d3" />
            </FlexBox>
          </FlexBox>
          <PackRevealMessage />

          {/* <FlexBox flexDirection={'column'} width={'100%'} justifyContent={'center'}>
          {PLATFORM_ORIGIN === ThemeEnum.RARIO && <D3TrialGame />}
          <PlayBigWinBig />
        </FlexBox> */}
          {
            // Not Required
            /* <ArcadeGameNudge /> */
          }

          {/* <HomePageWidgets /> */}
        </FlexBox>

        {/* <HowRarioWorksCard />
      <LearnAboutD3 />

      <CustomGutterSpaceRow flexDirection={'column'} margin="0 20px 10px">
        <TextField
          textTransform="uppercase"
          fontFamily={theme.fonts.primary}
          letterSpacing={theme.letterSpacings.primary}
          color={theme.colors.customWhite}
          fontWeight={800}
          fontSize={'40px'}
          lineHeight={'42px'}
        >
          INVEST IN CRICKETERS
        </TextField>
        <TextField
          textTransform="uppercase"
          fontFamily={theme.fonts.primary}
          letterSpacing={theme.letterSpacings.primary}
          color={theme.colors.customWhite}
          fontWeight={800}
          fontSize={'20px'}
          lineHeight={'32px'}
        >
          WIN BIG REWARDS
        </TextField>
      </CustomGutterSpaceRow> */}

        <FlexBox mb={'100px'}>
          <HomeLandingFooter showSocialLinks={true} />
        </FlexBox>
        {/* {!mobileView && PLATFORM_ORIGIN === ThemeEnum.RARIO && <DownloadBanner />} */}
        {/* {showPracticeBanner && (
        <PracticeMatchBanner showPracticeBannerCallback={showPracticeBannerCallback} />
      )} */}
      </>
    </InstantSearch>
  )
}

export default HomePage

const Stats: React.FunctionComponent<any> = ({ nbHits }: { nbHits: number }) => {
  const { setUserPacksCount } = useContext(BuyBackEstimateContext)
  useEffect(() => {
    setUserPacksCount(nbHits)
  }, [nbHits])
  if (nbHits === 0) return null
  return (
    <>
      <FlexBox
        padding="10px"
        justifyContent="space-between"
        mx="16px"
        mt="12px"
        borderRadius="5px"
        border="0.366px solid #FF867E"
        borderBottomWidth="0px"
        background="linear-gradient(180deg, #CD413A 26.09%, rgba(104, 37, 37, 0.60) 94.2%)"
        boxShadow="5.496px 0px 9.16px 0px rgba(0, 0, 0, 0.45)"
      >
        <TextField
          fontFamily={theme.fonts.primary}
          fontSize="12px"
          fontWeight="400"
          lineHeight="16px"
        >
          Check & reveal any unopened packs by March 15th
        </TextField>

        <Link href={'/profile/packs'}>
          <FlexBox minWidth="75px" pl="10px" alignItems="center">
            <TextField
              fontFamily={theme.fonts.primary}
              fontSize="12px"
              fontWeight="700"
              lineHeight="16px"
              textDecoration="underline"
              cursor="pointer"
            >
              Check Now
            </TextField>
          </FlexBox>
        </Link>
      </FlexBox>
    </>
  )
}

const PackRevealMessage = connectStats(Stats)
